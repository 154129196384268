import { Inject, Injectable } from '@angular/core';
import { ComponentStore, OnStateInit } from '@ngrx/component-store';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  finalize,
  pipe,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../../root';
import { PatientDetailSelectors } from '../patient-detail.selectors';
import { IPatientSymptomsComponentState } from './patient-symptoms.state';
import { ISymptomsService, SYMPTOMS_SERVICE } from '@main-data-access-services';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class PatientSymptomsComponentStore
  extends ComponentStore<IPatientSymptomsComponentState>
  implements OnStateInit
{
  //#region Properties

  protected readonly _patient$ = this._store.select(
    PatientDetailSelectors.patient
  );

  protected readonly _refresh$ = new BehaviorSubject<Date>(new Date());

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(SYMPTOMS_SERVICE)
    protected readonly _symptomsService: ISymptomsService,
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService,
    protected readonly _store: Store<IRootState>
  ) {
    super({
      searchResult: {
        total: 0,
        records: [],
      },
      search: {
        page: 1,
        limit: 10,
      },
      loading: false,
    });
  }

  //#endregion

  //#region Lifecycle

  public ngrxOnStateInit(): void {
    this.searchPatientSymptoms();
  }

  //#endregion Lifecycle

  //#region Methods

  public readonly searchPatientSymptoms = this.effect<never>(
    pipe(
      switchMap(() => {
        return combineLatest([
          this._patient$.pipe(take(1)),
          this.select((x) => x.search),
          this._refresh$,
        ]);
      }),
      debounceTime(250),
      switchMap(([patient, condition]) => {
        this.patchState({
          loading: true,
        });
        return this._symptomsService
          .getByPatientMrnAsync(condition, patient!.username)
          .pipe(
            tap((loadSymptomsResult) =>
              this.patchState({
                searchResult: loadSymptomsResult,
              })
            ),
            catchError((exception) => {
              console.log(exception);
              this._alertService.error('Error', exception.message);
              return EMPTY;
            }),
            finalize(() => {
              this.patchState({
                loading: false,
              });
            }),
            catchError(() => {
              this._alertService.error(
                'Error',
                `Failed to get patient's symptoms`
              );
              return EMPTY;
            })
          );
      })
    )
  );

  public readonly updatePage = this.updater<
    IPatientSymptomsComponentState['search']['page']
  >((state, page) => ({
    ...state,
    search: {
      ...state.search,
      page,
    },
  }));

  public readonly updateSearch = this.updater<
    IPatientSymptomsComponentState['search']
  >((state, search) => ({
    ...state,
    search: {
      ...state.search,
      ...search,
      page: 1,
    },
  }));

  public refresh(): void {
    this._refresh$.next(new Date());
  }

  //#endregion Methods
}
