import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { IClinician } from '@main-data-access-interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { SearchResult } from '@main-data-access-models';
import { IClinicianService } from './clinician-service.interface';

@Injectable()
export class ClinicianService implements IClinicianService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    private readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getAsync(condition?: {page: number; limit: number}): Observable<SearchResult<IClinician>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        let httpParams: HttpParams = new HttpParams();
        if (condition && condition.limit && condition.page) {
          httpParams.append('pager[page]', condition.page);
          httpParams = httpParams.append('pager[limit]', condition.limit);
        }
        const apiUrl = `${baseUrl}/clinicians`;
        return this._httpClient.get<SearchResult<IClinician>>(apiUrl, {params: httpParams});
      })
    );
  }

  //#endregion Methods
}
