import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IPatientService, PATIENT_SERVICE } from '@main-data-access-services';
import { IPatientDetailTreatmentComponentState } from './patient-treatments.state';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  finalize,
  map,
  mergeMap,
  pipe,
  switchMap,
  take,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../../root';
import { PatientDetailSelectors } from '../patient-detail.selectors';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class PatientDetailTreatmentComponentStore extends ComponentStore<IPatientDetailTreatmentComponentState> {
  //#region Properties

  public readonly patient$ = this._store.select(PatientDetailSelectors.patient);

  protected readonly _refresh$ = new BehaviorSubject<Date>(new Date());

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(PATIENT_SERVICE)
    private readonly _patientService: IPatientService,
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService,
    protected readonly _store: Store<IRootState>
  ) {
    super({
      condition: {
        page: 1,
        limit: 10,
      },
      searchResult: {
        records: [],
        total: 0,
      },
      loading: false,
    });
  }

  //#endregion

  //#region Methods

  public readonly searchTreatments = this.effect<never>(
    pipe(
      switchMap(() => {
        return combineLatest([
          this.patient$.pipe(take(1)),
          this.select((x) => x.condition).pipe(take(1)),
          this._refresh$,
        ]);
      }),
      mergeMap(([patient, condition]) => {
        this.patchState({
          loading: true,
        });
        condition.patientId = patient!.id;
        this.patchState({ condition });
        return this._patientService.searchTreatmentsAsync(condition).pipe(
          map((searchResult) => {
            this.patchState({
              searchResult: searchResult,
            });
          }),
          finalize(() => {
            this.patchState({
              loading: false,
            });
          }),
          catchError(() => {
            this._alertService.error(
              'Error',
              `Failed to get patient's treatments`
            );
            return EMPTY;
          })
        );
      })
    )
  );

  public refresh(): void {
    this._refresh$.next(new Date());
  }

  //#endregion
}
