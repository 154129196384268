import { NgModule } from '@angular/core';
import { LocationService } from './location.service';
import { LOCATION_SERVICE } from './location-service.constants';

@NgModule({
  providers: [
    {
      provide: LOCATION_SERVICE,
      useClass: LocationService,
    },
  ],
})
export class LocationServiceModule {}
