import { Inject, Injectable } from '@angular/core';
import {
  IValidationSummarizerOptionProvider,
  VALIDATION_SUMMARIZER_OPTIONS_PROVIDER,
  ValidationSummarizerService,
} from '@ui-tool/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class BasicValidationSummarizerService extends ValidationSummarizerService {
  //#region Constructor

  // tslint:disable-next-line:max-line-length
  public constructor(
    @Inject(VALIDATION_SUMMARIZER_OPTIONS_PROVIDER)
    validationSummarizerOptionProvider: IValidationSummarizerOptionProvider
  ) {
    super(validationSummarizerOptionProvider);
  }

  //#endregion

  //#region Internal methods

  // Build validation message from specific information.
  public override buildValidationMessage(
    controlLabel: string,
    validatorName: string,
    additionalValue: { [key: string]: string }
  ): string {
    if (!this._validatorNameToValidationMessage) {
      return '';
    }

    let message = '';
    switch (validatorName) {
      case 'required':
        message = `${controlLabel} is required`;
        break;
      case 'email':
        message = `${controlLabel} must be correct format`;
        break;
      default:
        if (
          this._validatorNameToValidationMessage &&
          this._validatorNameToValidationMessage[validatorName]
        ) {
          message = this._validatorNameToValidationMessage[validatorName];
        }
        break;
    }
    return message;
  }

  //#endregion
}
