import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import {
  catchError,
  EMPTY,
  map,
  mergeMap,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { PatientDetailRouteParams } from '@main-data-access-models';
import { IPatientService, PATIENT_SERVICE } from '@main-data-access-services';
import { ExceptionCodes, RequirementNames } from '@main-data-access-enums';
import { Store } from '@ngrx/store';
import { IPatientDetailState } from '@main-data-access-stores';
import { PatientDetailActions } from '@main-data-access-stores';
import {
  IMeetRequirementService,
  REQUIREMENT_SENTINEL_SERVICE,
} from '@ui-tool/core';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class PatientsGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService,
    @Inject(PATIENT_SERVICE)
    protected readonly _patientService: IPatientService,
    @Inject(REQUIREMENT_SENTINEL_SERVICE)
    protected readonly _requirementSentinelService: IMeetRequirementService,
    protected readonly _store: Store<IPatientDetailState>
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const routeParams =
      activatedRouteSnapshot.params as PatientDetailRouteParams;
    const username = routeParams?.username;

    return this._requirementSentinelService
      .shouldRequirementMetAsync(RequirementNames.ABLE_TO_VIEW_PATIENTS)
      .pipe(
        mergeMap((ableToView) => {
          if (!ableToView) {
            return throwError(
              () => ExceptionCodes.DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_FEATURE
            );
          }

          return of(true);
        }),
        catchError((exception) => {
          let message = 'Something went wrong while accessing this feature';

          if (
            exception ===
            ExceptionCodes.DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_FEATURE
          ) {
            message = 'You dont have enough permission to access this feature';
          }

          this._alertService.error('Error', message);

          return of(false);
        })
      );
  }

  //#endregion
}
