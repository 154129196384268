import { Inject, Injectable } from '@angular/core';

import { map, mergeMap, Observable } from 'rxjs';
import {
  IAppointment,
  IAppointmentHistory, IAppointmentPayload
} from '@main-data-access-interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { SearchResult } from '@main-data-access-models';
import { IAppointmentService } from '@main-data-access-services';

@Injectable()
export class AppointmentService implements IAppointmentService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    private readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getAppointmentsAsync(condition: {
    page: number;
    limit: number;
    patientId: string;
  }): Observable<SearchResult<IAppointment>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        let httpParams: HttpParams = new HttpParams().append(
          'pager[page]',
          condition.page
        );
        httpParams = httpParams.append('pager[limit]', condition.limit);
        httpParams = httpParams.append('patientId', condition.patientId);
        const apiUrl = `${baseUrl}/appointments`;
        return this._httpClient
          .get<SearchResult<IAppointment>>(apiUrl, { params: httpParams })
          .pipe(
            map((result: SearchResult<IAppointment>) => {
              // TODO: need to change format datetime
              const updatedRecords = result.records.map((record) => ({
                ...record,
                startTime: record.startTime
                  ? record.startTime.replace('Z', '')
                  : record.startTime,
              }));

              return {
                ...result,
                records: updatedRecords,
              };
            })
          );
      })
    );
  }

  public getHistoriesByAppointmentIdAsync(
    appointmentId: string
  ): Observable<IAppointmentHistory[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/appointments/${appointmentId}/histories`;
        return this._httpClient.get<IAppointmentHistory[]>(apiUrl);
      })
    );
  }

  public cancelAsync(id: string, cancellationReason: string): Observable<void> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/appointment/${id}`;
        const option = {
          body: {cancellationReason: cancellationReason}
        }
        return this._httpClient.delete<void>(apiUrl, option);
      })
    );
  }

  public createAsync(condition: IAppointmentPayload): Observable<IAppointment> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/appointment`;
        return this._httpClient.post<IAppointment>(apiUrl, condition);
      })
    );
  }

  //#endregion Methods
}
