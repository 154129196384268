import { NgModule } from '@angular/core';
import { ClinicianService } from './clinician.service';
import { CLINICIAN_SERVICE } from './clinician-service.constants';

@NgModule({
  providers: [
    {
      provide: CLINICIAN_SERVICE,
      useClass: ClinicianService,
    },
  ],
})
export class ClinicianServiceModule {}
