import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@main-data-access-enums';
import { PatientDetailRouteParams } from '@main-data-access-models';

export class PatientDetailNavigationRequest extends NavigateToScreenRequest<PatientDetailRouteParams> {
  //#region Constructor

  public constructor(username: string) {
    super(ScreenCodes.PATIENT_DETAIL, new PatientDetailRouteParams(username));
  }

  //#endregion
}
