import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  finalize,
  mergeMap,
  pipe,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../../root';
import { PatientDetailSelectors } from '../patient-detail.selectors';
import {
  IMedicationsService,
  PATIENT_MEDICATION_SERVICE,
} from '@main-data-access-services';
import { IPatientMedicationsComponentState } from './patient-medications.state';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class PatientMedicationsComponentStore extends ComponentStore<IPatientMedicationsComponentState> {
  //#region Properties

  protected readonly _patient$ = this._store.select(
    PatientDetailSelectors.patient
  );

  protected readonly _refresh$ = new BehaviorSubject<Date>(new Date());

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(PATIENT_MEDICATION_SERVICE)
    protected readonly _patientMedicationService: IMedicationsService,
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService,
    protected readonly _store: Store<IRootState>
  ) {
    super({
      searchResult: {
        total: 0,
        records: [],
      },
      condition: {
        page: 1,
        size: 10,
      },
      loading: false,
    });
  }

  //#endregion

  //#region Methods

  public readonly searchPatientMedications = this.effect<never>(
    pipe(
      switchMap(() => {
        return combineLatest([
          this._patient$.pipe(take(1)),
          this.select((x) => x.condition).pipe(take(1)),
          this._refresh$,
        ]);
      }),
      mergeMap(([patient, condition, search]) => {
        this.patchState({
          loading: true,
        });
        this.patchState({ condition });
        return this._patientMedicationService
          .getByMrnAsync(condition, patient!.username)
          .pipe(
            tap((loadMedicationsResult) => {
              this.patchState({
                searchResult: loadMedicationsResult,
              });
            }),
            finalize(() => {
              this.patchState({
                loading: false,
              });
            }),
            catchError(() => {
              this._alertService.error(
                'Error',
                `Failed to get patient's medications`
              );
              return EMPTY;
            })
          );
      })
    )
  );

  public refresh(): void {
    this._refresh$.next(new Date());
  }

  //#endregion Methods
}
